<template>
  <router-view/>
</template>

<style>
  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
</style>

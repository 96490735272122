const CryptoJS = require('crypto-js');

export function getSignature(appId, secret, ts) {
    try {
        const auth = md5(appId + ts);
        return hmacSHA1Encrypt(auth, secret);
    } catch (e) {
        return null;
    }
}

function hmacSHA1Encrypt(encryptText, encryptKey) {
    try {
        const hmac = CryptoJS.HmacSHA1(encryptText, encryptKey);
        return CryptoJS.enc.Base64.stringify(hmac);
    } catch (e) {
        throw new Error('Error in HMAC encryption: ' + e.message);
    }
}

function md5(cipherText) {
    try {
        const md5 = CryptoJS.MD5(cipherText);
        return md5.toString();
    } catch (e) {
        return null;
    }
}
// export function assembleAuthUrl(hosturl, apiKey, apiSecret) {
export function assembleAuthUrl(hosturl, apiKey, apiSecret) {
    const ul = new URL(hosturl);
    //签名时间
    const date = new Date().toUTCString();
    //参与签名的字段 host ,date, request-line
    const signString = `host: ${ul.host}\ndate: ${date}\nGET ${ul.pathname} HTTP/1.1`;
    //签名结果
    const sha = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(signString, apiSecret));
    //构建请求参数 此时不需要urlencoding
    const authUrl = `api_key="${apiKey}", algorithm="hmac-sha256", headers="host date request-line", signature="${sha}"`;
    //将请求参数使用base64编码
    const authorization = btoa(authUrl);
    const params = new URLSearchParams({
        host: ul.host,
        date,
        authorization
    });
    //将编码后的字符串url encode后添加到url后面
    return hosturl + "?" + params.toString();
}
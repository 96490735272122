const appId = 'e4902000'
const secret = 'NGU5OTI3YjllOWE0NjFhMTE2ZmM4YzE0'
const APIKey = 'aef54b0a753010bbddf0de15edf09fd4'
//普通AI接口
const wssUrl = 'wss://chatdoc.xfyun.cn/openapi/chat'
//语音识别AI接口
const iatUrl = 'wss://iat-api.xfyun.cn/v2/iat';
//语音合成AI接口
const ttlUrl = 'wss://tts-api.xfyun.cn/v2/tts'
//长文本语音
const dtsUrl = 'http://api-dx.xf-yun.com/v1/private/dts_create'
const file = "./16k_10.pcm" //请填写您的音频文件路径
const uri = "/v2/iat";
const highWaterMark = 1280;
const host = "iat-api.xfyun.cn";
// 帧定义
const FRAME = {
    STATUS_FIRST_FRAME: 0,
    STATUS_CONTINUE_FRAME: 1,
    STATUS_LAST_FRAME: 2
}
const chatExtends = {
    wikiFilterScore: 0.83,
    temperature: 0.5,
    sparkWhenWithoutEmbedding: true
}
const config = {
    wssUrl,
    iatUrl,
    ttlUrl,
    dtsUrl,
}
export default {
    appId,
    secret,
    APIKey,
    file,
    uri,
    highWaterMark,
    host,
    FRAME,
    chatExtends,
    config
}
<template>
  <div class="login-container">
    <el-card class="login-card">
      <h2 class="login-title">ログイン</h2>
      <el-form
          ref="loginForm"
          :model="loginForm"
          label-width="80px"
          class="login-form"
          :rules="loginRules"
      >
        <el-form-item label="ユーザー名" prop="username"
                      hide-required-asterisk="true">
          <el-input
              v-model="loginForm.username"
              placeholder="ユーザー名を入力してください"
              clearable
              :prefix-icon="User"
          ></el-input>
        </el-form-item>
        <el-form-item label="パスワード" prop="password">
          <el-input
              type="password"
              v-model="loginForm.password"
              placeholder="パスワードを入力してください"
              clearable
              show-password
              :prefix-icon="Lock"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login" class="login-button">ログイン</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {User, Lock} from "@element-plus/icons-vue";

export default {
  computed: {
    User() {
      return User
    },
    Lock() {
      return Lock
    },
  },
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          {required: false, message: '请输入用户名', trigger: 'blur'}
        ],
        password: [
          {required: false, message: '请输入密码', trigger: 'blur'}
        ]
      },
    };
  },
  methods: {
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.loginForm.username === 'AIxaioli' && this.loginForm.password === 'AIXL123456') {
            this.$router.push('/home');
          } else {
            ElMessage({
              message: '请填写正确的用户名和密码',
              type: 'error',
              duration: 5 * 1000
            })
          }
        }
      });
    }
  }
};
</script>

<style src="@/assets/css/LoginViewCss.css">
</style>
import {assembleAuthUrl} from "@/assets/apiAuthAlgorithm";
import config from "@/assets/config";
import {Base64} from "js-base64";

let bufferArry = []; // 存储所有音频数据的数组
let audio = new Audio()

export function ttlAPI(text) {
    const ws = new WebSocket(assembleAuthUrl(config.config.ttlUrl, config.APIKey, config.secret));
    ws.onopen = () => {
        const frame = {
            common: {
                app_id: config.appId,
            },
            business: {
                aue: 'lame',
                sfl: 1,
                vcn: 'xiaoyan',
                pitch: 50,
                speed: 50,
                tte: 'UTF8',
            },
            data: {
                status: 2,
                text: Base64.encode(text),
            },
        };
        ws.send(JSON.stringify(frame));
    };

    ws.onmessage = (e) => {
        let res = JSON.parse(e.data)
        if (res.code !== 0) {
            console.log(`${res.code}: ${res.message}`)
            ws.close()
            return
        }
        // 响应正常，获取语音文件的二进制数据并进行处理
        const chunk = res.data.audio;
        const binaryData = new Uint8Array(Base64.atob(chunk).length);
        for (let i = 0; i < Base64.atob(chunk).length; i++) {
            binaryData[i] = Base64.atob(chunk).charCodeAt(i);
        }
        bufferArry.push(binaryData)//元素的"canplaythrough"事件
        if (res.code === 0 && res.data.status === 2) {
            ws.close()
        }
    };

    ws.onerror = (event) => {
        // 处理错误
        console.log(event)
    };

    ws.onclose = () => {
        // 连接关闭时的处理
        let totalLength = 0
        bufferArry.forEach(res => {
            totalLength += res.length
        })
        const newBinaryData = new Uint8Array(totalLength);
        let offset = 0;
        bufferArry.forEach(binaryData => {
            newBinaryData.set(binaryData, offset);
            offset += binaryData.length;
        });
        let blob = new Blob([newBinaryData.buffer], {type: 'audio/mp3'}); // 创建Blob对象
        // audio = ; // 音频元素
        audio.src = URL.createObjectURL(blob)
        audio.play().then(() => {
            bufferArry = []
            URL.revokeObjectURL(URL.createObjectURL(blob))
        })
    };
}

export function audioPause() {
    audio.pause()
}
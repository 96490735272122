<template>
  <div class="chat-container">
    <div class="header">
      <p class="headerBut" @click="$router.push('/')">返回</p>
      <h3 class="headerText">LLama2+RAG</h3>
    </div>
    <div class="message-container" ref="messageContainer">
      <div v-for="(message, index) in messages" :key="index" class="message"
           :class="{ 'user-message': message.from === 'user','bot-message': message.from === 'bot'||message.from ==='botB','bot-blank': message.from==='bot-blank','user-blank': message.from==='user-blank'}">
        <!--        客人-->
        <div v-if="message.from==='user'" class="user-message">
          {{ message.text }}
          <el-icon class="is-loading" v-if="!message.text">
            <Loading/>
          </el-icon>
        </div>

        <!--        AI-->
        <div v-if="message.from==='bot'" class="bot-message">
          {{ message.text }}
          <el-icon class="is-loading" v-if="!message.text">
            <Loading/>
          </el-icon>
        </div>
        <div v-if="message.from==='botB'" class="bot-message">
          <p></p>
          <div v-html="renderedMarkdown(message.text)"></div>
          <el-icon class="is-loading" v-if="!message.text">
            <Loading/>
          </el-icon>
        </div>
        <div v-if="message.from==='bot-blank'||message.from==='user-blank'">
          {{ new Date().toLocaleString() }}
        </div>
      </div>
    </div>
    <div class="input-container">
      <!--            <el-upload-->
      <!--                action="#"-->
      <!--                :limit="1"-->
      <!--                :auto-upload="false"-->
      <!--                :on-preview="uploadMessage"-->
      <!--            >上传-->
      <!--            </el-upload>-->
      <!--      语音-->
      <Service class="no-inherit" @click="isSend=!isSend" v-if="isSend"/>
      <Microphone class="no-inherit" @click="isSend=!isSend" v-if="!isSend"/>
      <!--      输入框-->
      <el-input v-model="newMessage"
                v-if="!isSend"
                placeholder="输入消息"
                class="input-box"
                :disabled="isButInp||isSend"
                @keydown.enter="sendMessage"></el-input>
      <!--      <bar class="input-box" :options="options" v-if="isSend"></bar>-->
      <canvas id="recordCanvas" ref="record" class="input-box" v-if="isSend"></canvas>
      <el-button @click="sendMessage"
                 :disabled="isButInp||isSend"
                 type="primary"
                 size="large"
                 class="send-but"
                 color="#337ecc"

                 clearable>送信
      </el-button>
      <audio ref="audioPlayer" v-if="false"></audio>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {getSignature} from "@/assets/apiAuthAlgorithm";
import {ElMessage} from "element-plus";
import {ChatDotSquare, Loading, Microphone, Service} from "@element-plus/icons-vue";
import {audioPause, ttlAPI} from "@/assets/AI/ttlAI";
import Recorder from 'js-audio-recorder'
import {iatAPI} from "@/assets/AI/iatAI";
import MarkdownIt from 'markdown-it';
import config from "@/assets/config";

export default {
  components: {Service, Loading, ChatDotSquare, Microphone},
  data() {
    return {
      messages: [{
        text: new Date().toLocaleString(),
        from: 'bot-blank',
        // time:
      }, {
        //text: '亲爱的客人，我是AI小立，目前可回答您关于东芝FC-2020AC和FC-7616AC的相关问题，请您提问。',
        text: '親愛なるお客様、私はAIの小立です。現在、東芝FC-2020ACおよびFC-7616ACに関する質問に答えることができますので、お尋ねください。',
        from: 'bot',
        // time: new Date().toLocaleString()
      }],
      newMessage: '',
      isSend: false,
      isButInp: false,
      text: '',
      audioText:'',
      MarkdownItT: true,
      i: 0,
      y: 1,
      //录音
      recorder: null,
      recording: false,
      audioBlob: null,
      drawRecordId: null,//波浪图
      timer: 0,
    };
  },
  computed: {},
  watch: {
    isSend() {
      console.log(this.isSend);
      if (this.isSend) {
        this.startRecording()
      } else {
        this.stopRecording()
      }
    },
    text() {
      this.textShow(this.text)
    },
    audioText(){
       this.audioTextShow(this.audioText)
    },
    // i() {
    //   if (this.i === this.y && this.y !== 0) {
    //     if (this.messages[this.messages.length - 1].from === 'bot') {
    //       ttlAPI(this.text)
    //     }
    //     this.isButInp = false
    //   }
    // },
  },
  methods: {
    MarkdownIt,
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        audioPause()
        this.$nextTick(() => {
          this.scrollToBottom();
        });
        this.i = 0;
        this.y = 100000000000000;
        this.isButInp = !this.isButInp
        this.messages.push({text: '', from: 'user-blank'});
        this.messages.push({text: this.newMessage, from: 'user'});
        this.messages.push({text: '', from: 'bot-blank'});
        // 在这里添加与智能客服对话的逻辑，例如发送消息并获取回复
        this.messages.push({text: '', from: 'bot'})
        let data = {
          chatExtends: config.chatExtends,
          fileIds: ["1d6ec5ae830e419fa5a62bc9bae38394", "3edfbf97a2dc4fa1b40c9bd09d9e47d8"],
          messages: [
            {
              role: "user",
              content: this.newMessage
            },
          ]
        }
        this.text = ''
        const that = this;
        // const ws = new WebSocket(config.config.wssUrl + '?appId=' + config.appId + '&timestamp=' + Math.floor(new Date() / 1000) + '&signature=' + getSignature(config.appId, config.secret, Math.floor(new Date() / 1000)))
        // ws.onopen = function () {
        //   ws.send(JSON.stringify(data))
        // }
        // ws.onmessage = function (e) {
        //   let res = JSON.parse(e.data)
        //   if (res.status === 1 || res.status === 2) {
        //     that.text += res.content
        //   }
        //   if (res.status === 2) {
        //     that.y = that.text.length
        //   }
        // }
        // ws.onclose = function () {
        // }
        let session_id = '12345691'
        let project = 'test1'
        axios.get('/api/answer' + '?session_id=' + session_id + '&project=' + project + '&question=' + this.newMessage,
            {
              'X-Client-Info': '{"a":"3000","ch":"1002","v":"5.2.1","e":"16753207015161962279272449","bc":"110100"}',
              'X-Host': 'mall.film-ticket.film.list'
            })
            .then(res => {
              console.log(res.data[0])
              let date = res.data[0]
              if (date.status){
                that.text += date.msg
              }
            })
            .catch(err => {
              console.log(err)
            })
        this.newMessage = ''
      } else {
        ElMessage({
          message: '发送消息为空!',
          type: 'error',
          duration: 5 * 1000
        })
      }

    },
    textShow(text) {
      const that = this;
      const timer = setInterval(() => {
        if (that.i <= text.length) {
          // 正则表达式匹配,是否有表格
          const regex = /(\|.*\|[\s\S]*?\|.*\|[\s\S]*?)(?=\n\n|\n$|$)/gm;
          const matches = this.text.match(regex);
          if (matches) {
            that.messages[that.messages.length - 1] = {text: text.slice(0, that.i++), from: 'botB'};
          } else {
            that.messages[that.messages.length - 1] = {text: text.slice(0, that.i++), from: 'bot'};
          }
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        } else {
          clearInterval(timer);
          this.isButInp = false
        }
      }, 100);
    },
    audioTextShow(audioText){
      const that = this;
      const timer = setInterval(() => {
        if (that.i <= audioText.length) {
          // 正则表达式匹配,是否有表格
          that.messages[that.messages.length - 1] = {text: audioText.slice(0, that.i++), from: 'user'};
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        } else {
          clearInterval(timer);
          this.isButInp = false
           this.messages.push({text: '', from: 'bot-blank'});
          // 在这里添加与智能客服对话的逻辑，例如发送消息并获取回复
          this.messages.push({text: '', from: 'bot'})
          let data = {
            chatExtends: config.chatExtends,
            fileIds: ["1d6ec5ae830e419fa5a62bc9bae38394", "3edfbf97a2dc4fa1b40c9bd09d9e47d8"],
            messages: [
              {
                role: "user",
                content: audioText
              },
            ]
          }
          this.text = ''
          const that = this;
          // const ws = new WebSocket(config.config.wssUrl + '?appId=' + config.appId + '&timestamp=' + Math.floor(new Date() / 1000) + '&signature=' + getSignature(config.appId, config.secret, Math.floor(new Date() / 1000)))
          // ws.onopen = function () {
          //   ws.send(JSON.stringify(data))
          // }
          // ws.onmessage = function (e) {
          //   let res = JSON.parse(e.data)
          //   if (res.status === 1 || res.status === 2) {
          //     that.text += res.content
          //   }
          //   if (res.status === 2) {
          //     that.y = that.text.length
          //   }
          // }
          // ws.onclose = function () {
          // }
          let session_id = '12345691'
          let project = 'test1'
          axios.get('/api/answer' + '?session_id=' + session_id + '&project=' + project + '&question=' + audioText,
              {
                'X-Client-Info': '{"a":"3000","ch":"1002","v":"5.2.1","e":"16753207015161962279272449","bc":"110100"}',
                'X-Host': 'mall.film-ticket.film.list'
              })
              .then(res => {
                console.log(res.data[0])
                let date = res.data[0]
                if (date.status){
                  that.text += date.msg
                }
              })
              .catch(err => {
                console.log(err)
              })
          }
        }, 100);
    },
    //文件上传
    uploadMessage(e) {
      const FormData = require('form-data');
      const data = new FormData();
      data.append('file', e.raw);
      data.append('fileType', 'wiki');
      axios.post('https://chatdoc.xfyun.cn/openapi/fileUpload', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          appId: 'e4902000',
          signature: getSignature('e4902000', 'NGU5OTI3YjllOWE0NjFhMTE2ZmM4YzE0', parseInt(new Date().getTime() / 1000)),
          timestamp: parseInt(new Date().getTime() / 1000),
        }
      }).then(res => {
        console.log(res);
      })
    },
    startRecording() {
      this.recorder = new Recorder({
        sampleRate: 16000,
        sampleBits: 16,
        numChannels: 1
      })
      this.timer = Date.now()
      const that = this;
      Recorder.getPermission().then(() => {
        ElMessage({
          message: '开始录音，再次点击停止',
          type: 'success'
        })
        that.recorder.start() // 开始录音
        this.drawRecord();
      }, () => {
        ElMessage({
          message: '请先允许该网页使用麦克风',
          type: 'warning'
        })
        // this.isSend = !this.isSend
      })
    },
    async stopRecording() {
      if (Date.now() - this.timer > 60000) {
        ElMessage({
          message: '语音超时60s，请重新录音',
          type: 'warning'
        })
        this.drawRecordId && cancelAnimationFrame(this.drawRecordId);
        this.drawRecordId = null;
      } else {
        this.recorder.stop() // 停止录音
        this.drawRecordId && cancelAnimationFrame(this.drawRecordId);
        this.drawRecordId = null;

        this.uploadWAVData(this.recorder.getWAVBlob());

        // await iatAPI(this.recorder.getPCMBlob()).then(res => {
        //   const that = this;
        //   let i = 0;
        //   const timer = setInterval(() => {
        //     if (i <= res.length) {
        //       that.newMessage = res.slice(0, i++);
        //     } else {
        //       clearInterval(timer);
        //     }
        //   }, 100);
        // })
      }

    },
    // 录音波浪图
    drawRecord() {
      // 用requestAnimationFrame稳定60fps绘制
      this.drawRecordId = requestAnimationFrame(this.drawRecord)
      this.drawWave({
        canvas: this.$refs.record,
        dataArray: this.recorder.getRecordAnalyseData(),
      });
    },
    // 绘制波形图
    drawWave({canvas, dataArray}) {
      const ctx = canvas.getContext("2d");
      const bufferLength = dataArray.length;
      // 填充背景色
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      // 设定波形绘制颜色
      ctx.lineWidth = 2;
      ctx.strokeStyle = "#409EFF";
      ctx.beginPath();
      let sliceWidth = (canvas.width * 1.0) / bufferLength, // 一个点占多少位置，共有bufferLength个点要绘制
          x = 0; // 绘制点的x轴位置
      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;
        if (i === 0) {
          // 第一个点
          ctx.moveTo(x, y);
        } else {
          // 剩余的点
          ctx.lineTo(x, y);
        }
        // 依次平移，绘制所有点
        x += sliceWidth;
      }
      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.stroke();
    },
    //表格渲染
    renderedMarkdown(text) {
      const md = new MarkdownIt();
// 在外部样式表中定义表格和单元格样式
      const css = `
.styled-table {
  border-collapse: collapse;
  margin: auto;
  width: 100%;
}

.styled-table td, .styled-table th {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
  background-color: #F2F6FC;
}

.styled-table tr:first-child th {
  background-color: #E6E8EB;
}
`;
// 创建 <style> 元素并将样式添加到其中
      const styleElement = document.createElement('style');
      styleElement.innerHTML = css;

// 将 <style> 元素添加到文档的头部
      document.head.appendChild(styleElement);
      return md.render(text).replace('<table>', '<table class="styled-table">');
    },
    //下滑至最底部
    scrollToBottom() {
      const messageContainer = this.$refs.messageContainer;
      messageContainer.scrollTop = messageContainer.scrollHeight;
    },
    
    uploadWAVData(audioBlob){
      this.i = 0;
      this.y = 100000000000000;
      this.messages.push({text: '', from: 'user-blank'});
      this.messages.push({text: '', from: 'user'});
      var formData = new FormData()
      // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
      const newbolb = new Blob([audioBlob], { type: 'audio/wav' })
      //获取当时时间戳作为文件名
      const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
      formData.append('file', fileOfBlob)
       console.log(fileOfBlob);
      axios.post('/tts/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        console.log(res);
        var tts = res.data.message;
        if(!tts){
          this.audioText = "【无法识别，请重新录音】";
        }else{
          this.audioText = tts;
        }
        // this.newMessage = res.data.message;
        

      })
    }
  }
};
</script>

<style src="@/assets/css/HomeViewCss.css" scoped>
</style>